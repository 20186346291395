import React, { useContext, useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import PaymentButton from "../../components/payment/Payment";
import { AuthContext } from "../../context/authContext";
import "./coinpuchse.scss";
import { makeRequest } from "../../axios";
import Load from "../../components/Load";

const CoinPurchase = () => {
  const state = useLocation().state;

  const [count, setCount] = useState(state?.coins || 0);
  const [price, setPrice] = useState(500);
  const [err, setErr] = useState(null);
  const [isLoading, setLoad] = useState(false);

  const navigate = useNavigate();

  const { currentUser } = useContext(AuthContext);
  const id = currentUser?.id;
  const formattedPrice = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  }).format(price);

  useEffect(() => {
    if (count < 1) {
      setCount(1);
    }
  }, [count]);

  // const increment = () => {
  //   setCount(count + 1);
  // };

  // const decrement = () => {
  //   setCount(count - 1);
  // };

  const finalPrice = count * price;

  const formattedPrice2 = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  }).format(finalPrice);

  const handlePurchase = async (e) => {
    try {
      setLoad(true);
      await makeRequest.post(`/users/addcoin`, { coins: count, id: id });
      navigate(`/profile/${currentUser.storename}`, {
        state: { fromm: "Coin purchase successful" },
      });
    } catch (err) {
      setErr(err);
      setLoad(false);
      console.log(err);
    }
  };

  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    const textToCopy = currentUser.storename;

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="counter-container">
      {err && <p>something went wrong</p>}
      {isLoading && <Load />}
      <p className="coin-display">Coins:{count}</p>
      <p className="price-display">Price per coin:{formattedPrice}</p>
      <p className="final-display"> Final price:{formattedPrice2}</p>
      {/* <button className="counter-button" onClick={decrement}>
        Decrement
      </button> */}
      <PaymentButton
        runSaveHandleItemPurchased={handlePurchase}
        finalPrice={finalPrice}
      />
      {/* <button onClick={handlePurchase}>confirm Purchase</button> */}

      {/* <button className="counter-button" onClick={increment}>
        Increment
      </button> */}

      <input
        placeholder="Set amount of coins (e.g., 50)"
        type="text"
        value={state && count}
        className="form-control"
        inputMode="numeric"
        onChange={(e) => setCount(e.target.value)}
      />

      <p className="text-center">
        <button onClick={copyToClipboard} className="btn btn-primary">
          {copied ? "Copied!" : "Copy Storename To Clipboard"}
        </button>{" "}
        <p className="text-capitalize">
          Invite More vendors with your unique storename and gain{" "}
          <h2>
            <b className="text-success">free coins!!!</b>
          </h2>
        </p>
      </p>
    </div>
  );
};

export default CoinPurchase;
