import React, { useState, useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { makeRequest } from "../../axios";
import { AuthContext } from "../../context/authContext";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
// import PaymentButton from "../../components/payment/Payment";
import Loading from "../../components/loading/Loading";

const Promote = () => {
  const state = useLocation().state;

  const [count, setCount] = useState(1);
  const [price, setPrice] = useState();
  const [err, setErr] = useState(null);
  const { currentUser } = useContext(AuthContext);
  const [isLoading, setLoad] = useState(false);

  const id = currentUser?.id;

  const [lowCoin, setlowCoin] = useState(false);
  const [needCoins, setneedCoins] = useState(0);
  const availcoin = state?.coins || 0;

  const navigate = useNavigate();

  useEffect(() => {
    if (count < 1) {
      setCount(1);
    } else if (count >= 6) {
      setPrice(17);
    } else {
      setPrice(20);
    }
  }, [count]);

  // const increment = () => {
  //   setCount(count + 1);
  //   console.log(availcoin);
  // };

  // const decrement = () => {
  //   setCount(count - 1);
  // };

  const finalPrice = count * price;

  // const formattedPrice2 = new Intl.NumberFormat("en-NG", {
  //   style: "currency",
  //   currency: "NGN",
  // }).format(finalPrice);

  // const formattedPrice = new Intl.NumberFormat("en-NG", {
  //   style: "currency",
  //   currency: "NGN",
  // }).format(price);

  const handleNoCoins = (e) => {
    setlowCoin(true);

    const coin = availcoin - finalPrice;
    setneedCoins(coin * -1);
  };
  const handleVerify = async (e) => {
    try {
      setLoad(true);

      await makeRequest.post(`/verify/`, { count, id: id, coins: finalPrice });
      navigate(`/profile/${currentUser.storename}`, {
        state: {
          fromm: "Account verified successfully!!!",
        },
      });
    } catch (err) {
      setErr(err);
    }
  };
  console.log(err);
  if (!currentUser) {
    return <Navigate to="/login" />;
  }
  if (!state) {
    return <Navigate to={`/profile/${currentUser.storename}`} />;
  }
  return (
    <div className="container mt-5">
      <div className="text-center mb-4">
        <h1 className="display-4">
          <b>Verify Account</b>
        </h1>
      </div>

      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading />
        </div>
      ) : (
        <div className="row justify-content-center">
          <div className="col-md-6">
            {lowCoin && (
              <div
                className="alert alert-danger alert-dismissible fade show text-capitalize text-center fixed-bottom"
                role="alert"
              >
                <p>
                  <h1>Not Enough Coins</h1>
                  <p>
                    You need {needCoins} more coins to carry out this command
                  </p>
                  <br />
                  <Link
                    to={"/buycoins"}
                    state={{ coins: needCoins }}
                    className="btn btn-primary text-capitalize"
                  >
                    Purchase More
                  </Link>
                </p>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setlowCoin(false)}
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
            )}
            <div className="card">
              <div className="card-body justify-content-between">
                <p className="count-display">
                  Number of Months:{" "}
                  <span className="badge bg-primary">{count}</span>
                </p>
                <p className="count-display">
                  Price Per Month:{" "}
                  <span className="badge bg-info">{price}</span>
                </p>
                <p className="count-display">
                  Total Price:{" "}
                  <span className="badge bg-success">{finalPrice}</span>
                </p>
              </div>
              <div className="card-footer d-flex justify-content-between">
                {/* <button className="btn btn-sm btn-danger" onClick={decrement}>
                  Decrement
                </button> */}
                {/* <PaymentButton
                  runSaveHandleItemPurchased={handleVerify}
                  finalPrice={finalPrice}
                /> */}

                <input
                  placeholder="Months for verification (e.g., 12)"
                  type="number"
                  className="form-control"
                  inputMode="numeric"
                  onChange={(e) => setCount(e.target.value)}
                />
              </div>

              {availcoin >= finalPrice ? (
                <button
                  className="btn btn-sm btn-primary"
                  onClick={handleVerify}
                >
                  Verify
                </button>
              ) : (
                <button
                  className="btn btn-sm btn-primary"
                  onClick={handleNoCoins}
                >
                  Verify
                </button>
              )}
              {/* <button className="btn btn-sm btn-success" onClick={increment}>
                  Increment
                </button> */}

              <p className="text-center text-success text-capitalize">
                activate a massive discount by verifying your account for 1 year
                and above
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Promote;
